import React from "react"

import Layout from "../components/layout/Layout"
import SEO from '../components/layout/Seo';
import Header from '../components/layout/Header'
import Container from '../components/layout/Container'
import HomeParallax from '../components/homePage/HomeParallax';
import Breadcrumbs from '../components/layout/Breadcrumbs'
import Playground from "../components/layout/Playground";
import { Animated } from 'react-animated-css';
import Map from '../components/layout/Map';
import Footer from '../components/layout/Footer';
import Preloader from '../components/layout/Preloader';

export default class AboutCompanyContainer extends React.Component {
	state = {
		loaded: false
	}
	
	componentDidMount = () => {
		setInterval(() => {
			this.setState({ loaded: true })
		}, 250)
	}
	
	render() {
		const { loaded } = this.state;
		const { location } = this.props;

		return (
			<Layout>
				{Boolean(!loaded) && <Preloader /> }
				<SEO pageTitle="O Firmie" />
				<Header location={location} />
				<Playground>
					<HomeParallax 
						title="O firmie"
						subtitle="Subtitle"
						animationIn="fadeIn"
						imageNumber={3}
					/>
					<Breadcrumbs
						way={[
							{
								title: 'O Firmie',
								url: '/o-firmie',
								active: true
							}
						]}
					/>
					<Animated>
						<Map />	
					</Animated>
				</Playground>
				<Footer />
			</Layout>
		)
	}
}
